import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiTextField from "@mui/material/TextField";

const TextField = styled(MuiTextField)(({ theme }) => ({
  "& fieldset": {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
}));

const EFTextField = (props) => {
  return <TextField {...props} />;
};

export default EFTextField;
