import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./hooks/authProvider ";
import PrivateRoute from "./routers/route";
import Layout from "./pages/_layout";
import Page401 from "./pages/page401";
import Page404 from "./pages/page404";
import SignIn from "./pages/signin";
// import Home from "./pages/home/page";
import Content from "./pages/content/page";
import CreatePost from "./pages/content/create";
import EditPost from "./pages/content/edit";
import Settings from "./pages/settings/page";
import Banner from "./pages/banner/page";
import CreateBanner from "./pages/banner/create";
import EditBanner from "./pages/banner/edit";
// import Video from "./pages/video/page";
// import CreateVideo from "./pages/video/create";
// import EditVideo from "./pages/video/edit";
import FAQ from "./pages/faq/page";
import CreateFAQ from "./pages/faq/create";
import EditFAQ from "./pages/faq/edit";
import CreateUser from "./pages/settings/user/create";
import EditUser from "./pages/settings/user/edit";
import ResetPassword from "./pages/settings/user/resetPassword";
import Research from "./pages/research/page";
import CreateResearch from "./pages/research/create";
// import EditVideo from "./pages/video/edit";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="*" element={<Page404 />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/404" element={<Page404 />} />
            <Route element={<PrivateRoute />}>
              <Route element={<Layout />}>
                <Route path="/401" element={<Page401 />} />
                <Route path="/" element={<Banner />} />
                <Route path="/banner" element={<Banner />} />
                <Route path="/banner/create" element={<CreateBanner />} />
                <Route path="/banner/edit/:id" element={<EditBanner />} />
                {/* <Route path="/video" element={<Video />} />
                <Route path="/video/create" element={<CreateVideo />} />
                <Route path="/video/edit/:id" element={<EditVideo />} /> */}
                <Route path="/content" element={<Content />} />
                <Route path="/content/create" element={<CreatePost />} />
                <Route path="/content/edit/:id" element={<EditPost />} />
                <Route path="/research" element={<Research />} />
                <Route path="/research/create" element={<CreateResearch />} />
                {/* <Route path="/research/edit/:id" element={<EditResearch />} /> */}
                <Route path="/faq" element={<FAQ />} />
                <Route path="/faq/create" element={<CreateFAQ />} />
                <Route path="/faq/edit/:id" element={<EditFAQ />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/settings/user/create" element={<CreateUser />} />
                <Route path="/settings/user/edit/:id" element={<EditUser />} />
                <Route
                  path="/settings/user/reset/:username"
                  element={<ResetPassword />}
                />
              </Route>
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}
