import { ALERT, ALERT_CLOSE } from "./../constants";

export const setAlert = (payload) => ({
  type: ALERT,
  payload,
});

export const closeAlert = (payload) => ({
  type: ALERT_CLOSE,
  payload,
});
