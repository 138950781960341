import * as React from "react";
import EFButton from "../../components/EFButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import EFTextField from "../../components/EFTextField";
import { StyledFormControl } from "../../components/EFSelect";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import EFTextEditor from "../../components/EFTextEditor";
import { httpClient } from "../../utils/httpClient";
import { imageUrl } from "../../constants";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import * as snackbarAlertActions from "../../actions/snackbarAlert.action";
import * as loadingActions from "../../actions/loading.action";
import { useNavigate } from "react-router-dom";
import { EFDialogConfirm } from "../../components/EFDialogConfirm";

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;
const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRefFile1 = React.useRef();
  const inputRefFile2 = React.useRef();
  const inputRefContent = React.useRef();
  const [coverFile1, setCoverFile1] = React.useState(null);
  const [coverFile2, setCoverFile2] = React.useState(null);
  const [contentFiles, setContentFile] = React.useState([]);
  const [contentFileMax, setContentFileMax] = React.useState(false);
  const [dataFetchImageContent, setDataFetchImageContent] = React.useState([]);
  const [isEndDate, setIsEndDate] = React.useState(false);
  const [dataForm, setDataForm] = React.useState({
    type: "1",
    subject: "",
    description: "",
    content: "",
    image_path_1: null,
    image_path_2: null,
    start: dayjs(),
    end: null,
    is_active: true,
  });
  React.useEffect(() => {
    fetchImageContents();
  }, []);

  async function fetchImageContents() {
    dispatch(loadingActions.setLoading(true));
    const response = await httpClient.get("/upload/fetchImages/content");
    dispatch(loadingActions.setLoading(false));
    if (response.status === 200 && response.data?.result) {
      setDataFetchImageContent(response.data?.result);
    }
  }

  const onChooseContentFile = () => {
    inputRefContent.current.click();
  };

  const handleContentFileOnChange = (event) => {
    const { files } = event.target;
    let sizes = 0;
    const validImageFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.match(imageTypeRegex)) {
        sizes += file.size;
        validImageFiles.push(file);
      }
    }
    if (validImageFiles.length) {
      setContentFile(validImageFiles);
      setContentFileMax(false);
      let fileSize = sizes / 1024 / 1024;
      fileSize = fileSize.toFixed(2);
      if (Number(fileSize) > 10) {
        setContentFileMax(true);
      }
      return;
    } else {
      setContentFileMax(false);
      setContentFile([]);
      return;
    }
  };

  React.useEffect(() => {
    const images = [],
      fileReaders = [];
    let isCancel = false;
    if (contentFiles.length) {
      contentFiles.forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            images.push(result);
          }
          if (
            images.length === contentFiles.length &&
            !isCancel &&
            !contentFileMax
          ) {
            dispatch(loadingActions.setLoading(true));
            httpClient
              .post("/upload/uploadImages", {
                type: "content",
                images: images,
              })
              .then((response) => {
                dispatch(loadingActions.setLoading(false));
                if (response.status === 200 && response.data?.result) {
                  fetchImageContents();
                  dispatch(
                    snackbarAlertActions.setAlert({
                      open: true,
                      type: "success",
                      message: response.data?.message,
                    })
                  );
                }
              });
          } else {
          }
        };
        fileReader.readAsDataURL(file);
      });
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [contentFiles]);

  // Handle the change event when a file is selected
  const handleCover1FileOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setCoverFile1(event.target.files[0]);
      const images = [],
        fileReaders = [];
      const fileReader = new FileReader();
      fileReaders.push(fileReader);
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result) {
          images.push(result);
        }
        setDataForm({
          ...dataForm,
          image_path_1: images[0],
        });
      };
      fileReader.readAsDataURL(event.target.files[0]);
    }
  };

  const onChooseCover1File = () => {
    inputRefFile1.current.click();
  };

  const removeCover1File = () => {
    setCoverFile1(null);
    setDataForm({
      ...dataForm,
      image_path_1: null,
    });
  };

  const handleCover2FileOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setCoverFile2(event.target.files[0]);
      const images = [],
        fileReaders = [];
      const fileReader = new FileReader();
      fileReaders.push(fileReader);
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result) {
          images.push(result);
        }
        setDataForm({
          ...dataForm,
          image_path_2: images[0],
        });
      };
      fileReader.readAsDataURL(event.target.files[0]);
    }
  };

  const onChooseCover2File = () => {
    inputRefFile2.current.click();
  };

  const removeCover2File = () => {
    setCoverFile2(null);
    setDataForm({
      ...dataForm,
      image_path_2: null,
    });
  };

  const handleOnTextChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const validDataForm = () => {
    if (validEmpty(dataForm.subject) === true) {
      return { status: false, message: "กรุณาระบุ 'ชื่อ'" };
    } else if (validEmpty(dataForm.description) === true) {
      return { status: false, message: "กรุณาระบุ 'คำบรรยาย'" };
    } else if (validEmpty(dataForm.content) === true) {
      return { status: false, message: "กรุณาระบุ 'เนื้อหา'" };
    } else if (dataForm.image_path_1 === null) {
      return { status: false, message: "กรุณาระบุ 'ภาพหน้าปก'" };
    } else if (dataForm.image_path_2 === null) {
      return { status: false, message: "กรุณาระบุ 'ภาพหน้าปก(ขนาดใหญ้)'" };
    } else if (validEmpty(dataForm.start) === true) {
      return { status: false, message: "กรุณาระบุ 'การแสดงวันที่แสดง'" };
    } else if (isEndDate && validEmpty(dataForm.end) === true) {
      return { status: false, message: "กรุณาระบุ 'การแสดงวันที่สิ้นสุด'" };
    }
    return { status: true, message: "success" };
  };

  const validEmpty = (data) => {
    if (
      data === null ||
      data === "" ||
      typeof data === "undefined" ||
      data === "<p><br></p>"
    ) {
      return true;
    }
    return false;
  };

  const handleOnSubmit = (event) => {
    if (validDataForm().status === true) {
      dataForm.start = dayjs(dataForm.start).format("YYYY-MM-DD");
      if (isEndDate && dataForm.end !== null) {
        dataForm.end = dayjs(dataForm.end).format("YYYY-MM-DD");
      }
      dispatch(loadingActions.setLoading(true));
      httpClient.post("/post", dataForm).then((response) => {
        dispatch(loadingActions.setLoading(false));
        if (response.status === 201 && response.data?.result.affectedRows > 0) {
          dispatch(
            snackbarAlertActions.setAlert({
              open: true,
              type: "success",
              message: response.data?.message,
            })
          );
          navigate("/content");
        }
      });
    } else {
      dispatch(
        snackbarAlertActions.setAlert({
          open: true,
          type: "warning",
          message: validDataForm().message,
        })
      );
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" color={"primary"}>
            สร้างเนื้อหา
          </Typography>
          <Typography variant="body1" color={"inherit"}>
            สร้างสื่อวิจัยหรือข่าวสาร
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <EFButton
            variant="contained"
            startIcon={<Icon>save</Icon>}
            disableElevation
            onClick={handleOnSubmit}
          >
            บันทึก
          </EFButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color={"primary"}
                sx={{ fontWeight: "bolder" }}
              >
                ภาพหน้าปก *
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!coverFile1 && (
                <Stack spacing={1} alignItems={"center"}>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    ref={inputRefFile1}
                    onChange={handleCover1FileOnChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    sx={{
                      width: "320px",
                      height: "240px",
                      fontSize: "18px",
                      fontWeight: "500",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "15px",
                      color: "#0b4e75",
                      backgroundColor: "#fff",
                      border: "1.5px dashed #b79c62",
                      borderRadius: "20px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }}
                    onClick={onChooseCover1File}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Icon
                        sx={{
                          fontSize: "50px",
                        }}
                        color="secondary"
                      >
                        cloud_upload
                      </Icon>
                    </Box>
                    เลือกรูปภาพ
                  </Button>
                  <Typography variant="body2" color={"text.secondary"}>
                    ไฟล์ : .png/jpg Resolution : 4:3
                  </Typography>
                  <Typography variant="caption" color={"text.secondary"}>
                    ขนาดที่แนะนำ : 600x480 หรือมากกว่า
                  </Typography>
                </Stack>
              )}
              {coverFile1 && (
                <Stack spacing={1} alignItems={"center"}>
                  <img
                    style={{
                      width: "320px",
                      height: "240px",
                      fontSize: "18px",
                      fontWeight: "500",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "15px",
                      color: "#0b4e75",
                      backgroundColor: "#fff",
                      border: "1.5px dashed #b79c62",
                      borderRadius: "20px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }}
                    src={URL.createObjectURL(coverFile1)}
                    alt="Thumb"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{coverFile1.name}</Typography>
                    <Typography
                      onClick={removeCover1File}
                      variant="body2"
                      color="secondary"
                      sx={{
                        marginLeft: 2,
                        ": hover": {
                          cursor: "pointer",
                          textDecoration: "underline",
                        },
                      }}
                      // startIcon={<Icon>delete</Icon>}
                    >
                      ลบ
                    </Typography>
                  </Box>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography
                variant="body1"
                color={"primary"}
                sx={{ fontWeight: "bolder" }}
              >
                ภาพหน้าปก(ขนาดใหญ่) *
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!coverFile2 && (
                <Stack spacing={1} alignItems={"center"}>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    ref={inputRefFile2}
                    onChange={handleCover2FileOnChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    sx={{
                      width: "320px",
                      height: "240px",
                      fontSize: "18px",
                      fontWeight: "500",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "15px",
                      color: "#0b4e75",
                      backgroundColor: "#fff",
                      border: "1.5px dashed #b79c62",
                      borderRadius: "20px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }}
                    onClick={onChooseCover2File}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Icon
                        sx={{
                          fontSize: "50px",
                        }}
                        color="secondary"
                      >
                        cloud_upload
                      </Icon>
                    </Box>
                    เลือกรูปภาพ
                  </Button>
                  <Typography variant="body2" color={"text.secondary"}>
                    ไฟล์ : .png/jpg Resolution : 4:3
                  </Typography>
                  <Typography variant="caption" color={"text.secondary"}>
                    ขนาดที่แนะนำ : 800x600 หรือมากกว่า
                  </Typography>
                </Stack>
              )}
              {coverFile2 && (
                <Stack spacing={1} alignItems={"center"}>
                  <img
                    style={{
                      width: "320px",
                      height: "240px",
                      fontSize: "18px",
                      fontWeight: "500",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "15px",
                      color: "#0b4e75",
                      backgroundColor: "#fff",
                      border: "1.5px dashed #b79c62",
                      borderRadius: "20px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }}
                    src={URL.createObjectURL(coverFile2)}
                    alt="Thumb"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{coverFile2.name}</Typography>
                    <Typography
                      onClick={removeCover2File}
                      variant="body2"
                      color="secondary"
                      sx={{
                        marginLeft: 2,
                        ": hover": {
                          cursor: "pointer",
                          textDecoration: "underline",
                        },
                      }}
                      // startIcon={<Icon>delete</Icon>}
                    >
                      ลบ
                    </Typography>
                  </Box>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} mt={2}>
              <Typography variant="body2" color={"text.secondary"}>
                Popular 4:3 Resolutions
              </Typography>
              <ul style={{ fontSize: "12px", color: "#646464" }}>
                {/* <li>160 x 120</li>
              <li>320 x 240 </li> */}
                <li>640 x 480 </li>
                <li>800 x 600 </li>
                <li>1024 x 768 </li>
                <li>1152 x 864 </li>
                <li>2048 x 1536 </li>
                <li>3200 x 2400 </li>
                <li>4096 x 3072 </li>
                <li>6400 x 4800 </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Grid
            // item
            // xs={12}
            // md={9}
            container
            spacing={2}
            // sx={{ display: "flex" }}
          >
            <Grid item xs={6}>
              <Typography
                variant="body1"
                color={"primary"}
                sx={{ fontWeight: "bolder" }}
              >
                รายละเอียดข้อมูลเนื้อหา
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent={"flex-end"}>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="secondary"
                    checked={dataForm.is_active || false}
                    onChange={(e) => {
                      setDataForm({ ...dataForm, is_active: e.target.checked });
                    }}
                  />
                }
                label="การแสดงผล : "
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" color={"inherit"}>
                ชื่อ *
              </Typography>
              <EFTextField
                fullWidth
                placeholder="โปรดระบุ"
                name="subject"
                value={dataForm.subject}
                onChange={handleOnTextChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" color={"inherit"}>
                ประเภท *
              </Typography>
              <StyledFormControl fullWidth>
                <Select
                  name="type"
                  value={dataForm.type}
                  onChange={handleOnTextChange}
                >
                  <MenuItem value={"1"}>สื่อวิจัย</MenuItem>
                  <MenuItem value={"2"}>สื่อวิจัย(วีดีโอ)</MenuItem>
                  <MenuItem value={"3"}>ข่าวสาร</MenuItem>

                  {/* <MenuItem value={"1"}>บทความวิจัย</MenuItem> */}
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                color={"inherit"}
                sx={{ height: "28px" }}
              >
                คำบรรยาย *
              </Typography>
              <EFTextField
                fullWidth
                placeholder="โปรดระบุ"
                multiline
                rows={4}
                name="description"
                value={dataForm.description}
                onChange={handleOnTextChange}
              />
            </Grid>
            {/* <Grid item xs={6}> */}
            <Grid item xs={6} md={3}>
              <Typography
                variant="body1"
                color={"inherit"}
                sx={{ height: "28px" }}
              >
                วันที่เริ่มแสดง *
              </Typography>
              <DatePicker
                // disablePast
                value={dayjs(dataForm.start) || null}
                onChange={(newValue) => {
                  setDataForm({
                    ...dataForm,
                    start: newValue,
                  });
                }}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "12px !important",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="space-between"
              >
                <Typography variant="body1" color={"inherit"}>
                  วันที่สิ้นสุด *
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      sx={{ padding: 0 }}
                      color="secondary"
                      value={!isEndDate}
                      onChange={(event) => {
                        setIsEndDate(!isEndDate);
                        if (isEndDate) {
                          setDataForm({
                            ...dataForm,
                            end: null,
                          });
                        }
                      }}
                    />
                  }
                  label="ไม่กำหนด"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                />
              </Stack>
              <DatePicker
                disabled={!isEndDate}
                minDate={dayjs(dataForm.start)}
                value={dataForm.end === null ? null : dayjs(dataForm.end)}
                onChange={(newValue) => {
                  setDataForm({
                    ...dataForm,
                    end: newValue,
                  });
                }}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "12px !important",
                  },
                }}
              />
            </Grid>
            {/* </Grid> */}
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color={"inherit"}
                sx={{ height: "28px" }}
              >
                เนื้อหา *
              </Typography>
              <EFTextEditor dataForm={dataForm} setDataForm={setDataForm} />
            </Grid>
            {/* <Grid item xs={12} mt={4}>
            <Stack direction={"column"} spacing={1}>
              <Typography
                variant="body1"
                color={"primary"}
                sx={{ fontWeight: "bolder" }}
              >
                ภาพรายละเอียด *
              </Typography>
              <Typography
                variant="caption"
                color={"text.secondary"}
                sx={{ margin: "0px !important" }}
              >
                อัพโหลดรูปเพื่อนำลิงค์ url ไปใส่ในกล่องจัดการข้อความ (Text
                editor) ครั้งละไม่เกิน 8MB
              </Typography>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  <Stack spacing={1} alignItems={"center"}>
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      ref={inputRefContent}
                      onChange={handleContentFileOnChange}
                      style={{ display: "none" }}
                      multiple
                    />
                    <Button
                      sx={{
                        width: "240px",
                        height: "180px",
                        fontSize: "14px",
                        fontWeight: "500",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "15px",
                        color: "#0b4e75",
                        backgroundColor: "#fff",
                        border: "1.5px dashed #b79c62",
                        borderRadius: "20px",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                        margin: "0px !important",
                      }}
                      onClick={onChooseContentFile}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          sx={{
                            fontSize: "40px",
                          }}
                          color="secondary"
                        >
                          cloud_upload
                        </Icon>
                      </Box>
                      อัพโหลดรูปภาพ(หลายภาพ)
                    </Button>
                    <Typography variant="body2" color={"text.secondary"}>
                      ไฟล์ : .png/jpg
                    </Typography>
                  </Stack>
                  {dataFetchImageContent &&
                    dataFetchImageContent.map((row, index) => {
                      return (
                        <Stack key={index} spacing={1} alignItems={"center"}>
                          <img
                            style={{
                              width: "240px",
                              height: "180px",
                              fontSize: "18px",
                              fontWeight: "500",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "15px",
                              color: "#0b4e75",
                              backgroundColor: "#fff",
                              border: "1.5px dashed #b79c62",
                              borderRadius: "20px",
                              cursor: "pointer",
                              transition: "all 0.3s ease",
                            }}
                            // src={URL.createObjectURL(coverFile1)}
                            src={`${imageUrl}${row.image_path}`}
                            alt="Thumb"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${imageUrl}${row.image_path}`
                                );
                                dispatch(
                                  snackbarAlertActions.setAlert({
                                    open: true,
                                    type: "success",
                                    message: "คัดลอกเรียบร้อย!",
                                  })
                                );
                              }}
                              variant="body2"
                              color="primary"
                              sx={{
                                marginRight: 1,
                                ": hover": {
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              คัดลอก
                            </Typography>
                            <Typography
                              variant="body2"
                              color="secondary"
                              sx={{
                                marginLeft: 1,
                                ": hover": {
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                },
                              }}
                              onClick={async () => {
                                const answer = await EFDialogConfirm({
                                  text: `ต้องการลบรูปภาพนี้ใช่หรือไม่?`,
                                  title: "ยืนยันการลบข้อมูล?",
                                  options: {
                                    trueButtonText: "ยืนยัน",
                                    falseButtonText: "ยกเลิก",
                                  },
                                });
                                if (answer) {
                                  const imageName = row.image_path.substring(
                                    row.image_path.lastIndexOf("/") + 1
                                  );
                                  httpClient
                                    .delete(`/upload/deleteImage/${imageName}`)
                                    .then((response) => {
                                      if (response.status === 201) {
                                        fetchImageContents();
                                        dispatch(
                                          snackbarAlertActions.setAlert({
                                            open: true,
                                            type: "success",
                                            message: response.data?.message,
                                          })
                                        );
                                      }
                                    });
                                }
                              }}
                            >
                              ลบ
                            </Typography>
                          </Box>
                        </Stack>
                      );
                    })}
                </Box>
              </Box>
            </Stack>
          </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Page;
