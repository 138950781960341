import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import EFButton from "../../components/EFButton";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { EFTableCell, EFTableRow } from "../../components/EFTable";
import { useNavigate } from "react-router-dom";
import EFActionBtn from "../../components/EFActionBtn";
import Stack from "@mui/material/Stack";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { httpClient } from "../../utils/httpClient";
import _ from "lodash";
import { EFDialogConfirm } from "../../components/EFDialogConfirm";
import { useDispatch } from "react-redux";
import * as snackbarAlertActions from "../../actions/snackbarAlert.action";
import * as loadingActions from "../../actions/loading.action";
import dayjs from "dayjs";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 220,
  "& fieldset": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#0000003b",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0000003b",
      borderWidth: "1px",
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: 300,
  "& fieldset": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#0000003b",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0000003b",
      borderWidth: "1px",
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: 100,
  color: theme.palette.common.white,
  borderTopRightRadius: 12,
  borderBottomRightRadius: 12,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
}));

const StyledButtonType = styled(Button)(({ theme }) => ({
  borderTopRightRadius: 12,
  borderBottomRightRadius: 12,
  borderTopLeftRadius: 12,
  borderBottomLeftRadius: 12,
  ":hover": {
    cursor: "unset !important",
  },
}));

const getTypeButtonStyled = (type) => {
  let background = "";
  let color = "";
  if (type === "1") {
    background = "#cfe9ff";
    color = "#0b4e75";
  } else if (type === "2") {
    background = "#fde9c0";
    color = "#a58236";
  } else if (type === "3") {
    background = "#eca484";
    color = "#a14629";
  }
  return { background, color };
};

const getTypeName = (type) => {
  let typeName = "";
  // if (type === "1") {
  //   typeName = "บทความวิจัย";
  // } else
  if (type === "1") {
    typeName = "สื่อวิจัย";
  } else if (type === "2") {
    typeName = "สื่อวิจัย(วีดีโอ)";
  } else if (type === "3") {
    typeName = "ข่าวสาร";
  }
  return typeName;
};

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataFetch, setDataFetch] = React.useState([]);
  const [dataClone, setDataClone] = React.useState([]);
  const [searchType, setSearchType] = React.useState("all");
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    dispatch(loadingActions.setLoading(true));
    const response = await httpClient.get(`/post`);
    dispatch(loadingActions.setLoading(false));
    if (response && response.status === 200 && response.data) {
      setDataFetch(response.data);
      setDataClone(_.clone(response.data));
    }
  }

  const handleSearch = () => {
    if (searchType !== "all") {
      const filtered = dataClone
        .filter((item) => item?.type.includes(searchType))
        .filter((item) =>
          (item?.subject + item?.description).includes(searchText)
        );
      setDataFetch(filtered);
    } else {
      const filtered = dataClone.filter((item) =>
        (item?.subject + item?.description).includes(searchText)
      );
      setDataFetch(filtered);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" color={"primary"}>
            การจัดการเนื้อหา
          </Typography>
          <Typography variant="body1" color={"inherit"}>
            จัดการสื่อวิจัยและข่าวสาร
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <Stack direction={"row"} spacing={1}>
            <EFButton
              variant="contained"
              startIcon={<Icon>post_add</Icon>}
              disableElevation
              onClick={() => {
                navigate("/content/create");
              }}
            >
              สร้างเนื้อหา
            </EFButton>
            {/* <EFButton
              variant="outlined"
              startIcon={<Icon>settings</Icon>}
              disableElevation
              sx={{ color: "#b79c62" }}
              onClick={() => {
                // navigate("/content/create");
              }}
            >
              ตั้งค่าเนื้อหาแนะนำ
            </EFButton> */}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <StyledFormControl>
              <Select
                value={searchType}
                onChange={(e) => {
                  setSearchType(e.target.value);
                }}
              >
                <MenuItem value={"all"}>ประเภททั้งหมด</MenuItem>
                <MenuItem value={"1"}>สื่อวิจัย</MenuItem>
                <MenuItem value={"2"}>สื่อวิจัย(วีดีโอ)</MenuItem>
                <MenuItem value={"3"}>ข่าวสาร</MenuItem>
              </Select>
            </StyledFormControl>
            <StyledTextField
              variant="outlined"
              placeholder="กรอกข้อความ"
              type="search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <StyledButton
              variant="contained"
              color="secondary"
              startIcon={<Icon>search</Icon>}
              disableElevation
              onClick={handleSearch}
            >
              ค้นหา
            </StyledButton>
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ marginBottom: 2 }}>
                  <EFTableCell sx={{ width: 120 }} align="center">
                    ลำดับ
                  </EFTableCell>
                  <EFTableCell align="center">ประเภท</EFTableCell>
                  <EFTableCell>หัวข้อ</EFTableCell>
                  <EFTableCell align="center">รายละเอียด</EFTableCell>
                  <EFTableCell align="center">การแสดงผล</EFTableCell>
                  <EFTableCell sx={{ width: 150 }}></EFTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataFetch &&
                  dataFetch.map((row, index) => {
                    return (
                      <EFTableRow key={index}>
                        <EFTableCell align="center">{index + 1}</EFTableCell>
                        <EFTableCell align="center">
                          <StyledButtonType
                            sx={{
                              color: getTypeButtonStyled(row.type).color,
                              backgroundColor: getTypeButtonStyled(row.type)
                                .background,
                              ":hover": {
                                backgroundColor: getTypeButtonStyled(row.type),
                              },
                            }}
                            disableRipple
                            disableElevation
                          >
                            {getTypeName(row.type)}
                          </StyledButtonType>
                        </EFTableCell>
                        <EFTableCell component="th" scope="row">
                          {row.subject}
                        </EFTableCell>
                        <EFTableCell align="center">
                          <Typography>{row.description}</Typography>
                          <Typography>
                            วันที่เริ่มแสดง :{" "}
                            {dayjs(row.start).format("DD-MM-YYYY")}
                          </Typography>
                          <Typography>
                            วันที่สิ้นสุด :{" "}
                            {row.end !== null
                              ? dayjs(row.end).format("DD-MM-YYYY")
                              : "-"}
                          </Typography>
                        </EFTableCell>
                        <EFTableCell align="center">
                          {row.is_active ? (
                            <Typography variant="body2" color={"secondary"}>
                              แสดง
                            </Typography>
                          ) : (
                            <Typography variant="body2" color={"error"}>
                              ไม่แสดง
                            </Typography>
                          )}
                        </EFTableCell>
                        <EFTableCell>
                          <Stack direction={"row"} spacing={1}>
                            <EFActionBtn
                              sx={{ backgroundColor: "white" }}
                              onClick={() => {
                                navigate(`/content/edit/${row.id}`);
                              }}
                            >
                              <Icon color="action">edit</Icon>
                            </EFActionBtn>
                            <EFActionBtn
                              sx={{ backgroundColor: "white" }}
                              onClick={async () => {
                                const answer = await EFDialogConfirm({
                                  text: `ต้องการลบข้อมูล '${row.subject}' หรือไม่?`,
                                  title: "ยืนยันการลบข้อมูล?",
                                  options: {
                                    trueButtonText: "ยืนยัน",
                                    falseButtonText: "ยกเลิก",
                                  },
                                });
                                if (answer) {
                                  httpClient
                                    .delete(`/post/${row.id}`)
                                    .then((response) => {
                                      if (response.status === 201) {
                                        fetchData();
                                        dispatch(
                                          snackbarAlertActions.setAlert({
                                            open: true,
                                            type: "success",
                                            message: response.data?.message,
                                          })
                                        );
                                      }
                                    });
                                }
                              }}
                            >
                              <Icon color="action">delete</Icon>
                            </EFActionBtn>
                          </Stack>
                        </EFTableCell>
                      </EFTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Page;
