import axios from "axios";
import join from "url-join";
import {
  apiUrl,
  NOT_CONNECT_NETWORK,
  NETWORK_CONNECTION_MESSAGE,
} from "../constants";
import * as loginActions from "../actions/login.action";

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers["Content-Type"] = "application/json;";
axios.interceptors.request.use(async (config) => {
  if (!isAbsoluteURLRegex.test(config.url)) {
    config.url = join(apiUrl, config.url);
  }
  const current = loginActions.getCurrentUser();
  if (current) {
    config.headers.Authorization = `Bearer ${current.Access_token}`;
  }
  config.timeout = 60000; // 60 Second
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // debugger;
    if (error?.response?.status === 403) {
      localStorage.removeItem("socialaraya.co.th");
    }
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else if (!error.response) {
      return Promise.reject({
        code: NOT_CONNECT_NETWORK,
        message: NETWORK_CONNECTION_MESSAGE,
      });
    }
    return Promise.reject(error);
  }
);

export const httpClient = axios;
