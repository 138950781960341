import React, { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../utils/httpClient";
import { useDispatch } from "react-redux";
import * as snackbarAlertActions from "../actions/snackbarAlert.action";
import * as loadingActions from "../actions/loading.action";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [access, setAccess] = useState(
    JSON.parse(localStorage.getItem("socialaraya.co.th")) || null
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    verifyToken();
  }, []);

  async function verifyToken() {
    try {
      dispatch(loadingActions.setLoading(true));
      const response = await httpClient.post(`/user/verifyToken`);
      dispatch(loadingActions.setLoading(false));
      if (response && !response.data && response.status === 403) {
        setAccess(null);
        localStorage.removeItem("socialaraya.co.th");
        navigate("/signin");
      }
    } catch (error) {
      dispatch(loadingActions.setLoading(false));
      setAccess(null);
      localStorage.removeItem("socialaraya.co.th");
      navigate("/signin");
    }
  }

  const signIn = async (data) => {
    try {
      dispatch(loadingActions.setLoading(true));
      const response = await httpClient.post(`/user/login`, data);
      dispatch(loadingActions.setLoading(false));
      if (response && response.data) {
        const ip = await httpClient.get(`/user/getIP`);
        const _data = response.data;
        const logined = {
          Logined: true,
          Username: _data.username,
          UserType: _data.usertype,
          Access_token: _data.access_token,
          IP: ip.data !== "" ? ip.data.replace("::ffff:", "") : "Unknown",
        };
        localStorage.setItem("socialaraya.co.th", JSON.stringify(logined));
        setAccess(logined);
        dispatch(
          snackbarAlertActions.setAlert({
            open: true,
            type: "success",
            message: "Sign-in successfully",
          })
        );
        navigate("/banner");
        return;
      }
      throw new Error(response.message);
    } catch (error) {
      dispatch(loadingActions.setLoading(false));
      dispatch(
        snackbarAlertActions.setAlert({
          open: true,
          type: "error",
          message: error && error?.response?.data?.error,
        })
      );
    }
  };

  const signOut = () => {
    setAccess(null);
    localStorage.removeItem("socialaraya.co.th");
    navigate("/signin");
  };

  return (
    <AuthContext.Provider value={{ access, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
