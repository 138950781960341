import * as React from "react";
import {
  Box,
  Grid,
  Icon,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EFButton from "../../../components/EFButton";
import { EFTableCell, EFTableRow } from "../../../components/EFTable";
import { httpClient } from "../../../utils/httpClient";
import _ from "lodash";
import EFActionBtn from "../../../components/EFActionBtn";
import { EFDialogConfirm } from "../../../components/EFDialogConfirm";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as snackbarAlertActions from "../../../actions/snackbarAlert.action";
import * as loadingActions from "../../../actions/loading.action";

const User = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataFetch, setDataFetch] = React.useState([]);
  const [dataClone, setDataClone] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    dispatch(loadingActions.setLoading(true));
    const response = await httpClient.get(`/user`);
    dispatch(loadingActions.setLoading(false));
    if (response && response.status === 200 && response.data) {
      setDataFetch(response.data);
      setDataClone(_.clone(response.data));
    }
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body1" color={"inherit"}>
            <u>หมายเหตุ</u> : รหัสผ่านค่าเริ่มต้น คือ <b>social123</b>
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <EFButton
            variant="contained"
            startIcon={<Icon>person_add</Icon>}
            disableElevation
            onClick={() => {
              navigate("/settings/user/create");
            }}
            sx={{ minHeight: 55 }}
          >
            เพิ่มผู้ใช้งาน
          </EFButton>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ marginBottom: 2 }}>
                  <EFTableCell sx={{ width: 120 }} align="center">
                    ลำดับ
                  </EFTableCell>
                  <EFTableCell align="center" sx={{ width: 320 }}>
                    Username
                  </EFTableCell>
                  <EFTableCell align="center" sx={{ width: 150 }}>
                    การใช้งาน
                  </EFTableCell>
                  <EFTableCell sx={{ width: 150 }}></EFTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataFetch &&
                  dataFetch.map((row, index) => {
                    return (
                      <EFTableRow key={index}>
                        <EFTableCell align="center">{index + 1}</EFTableCell>
                        <EFTableCell align="center" component="th" scope="row">
                          {row.username}
                        </EFTableCell>
                        <EFTableCell align="center">
                          {row.is_active ? (
                            <Typography variant="body2" color={"secondary"}>
                              ใช้งาน
                            </Typography>
                          ) : (
                            <Typography variant="body2" color={"error"}>
                              ปิดการใช้งาน
                            </Typography>
                          )}
                        </EFTableCell>
                        <EFTableCell>
                          <Stack direction={"row"} spacing={1}>
                            {row.usertype !== 1 && (
                              <>
                                <EFActionBtn
                                  sx={{ backgroundColor: "white" }}
                                  onClick={() => {
                                    navigate(`/settings/user/edit/${row.id}`);
                                  }}
                                >
                                  <Icon color="action">edit</Icon>
                                </EFActionBtn>
                                <EFActionBtn
                                  sx={{ backgroundColor: "white" }}
                                  onClick={async () => {
                                    const answer = await EFDialogConfirm({
                                      text: `ต้องการรีเช็ตรหัสผ่านของผู้ใช้งาน '${row.username}' เป็นรหัสผ่านค่าเริ่มต้นหรือไม่?`,
                                      title: "ยืนยันการรีเช็ตรหัสผ่าน?",
                                      options: {
                                        trueButtonText: "ยืนยัน",
                                        falseButtonText: "ยกเลิก",
                                      },
                                    });
                                    if (answer) {
                                      httpClient
                                        .put(`/user/resetPassword`, {
                                          id: row.id,
                                        })
                                        .then((response) => {
                                          if (response.status === 201) {
                                            fetchData();
                                            dispatch(
                                              snackbarAlertActions.setAlert({
                                                open: true,
                                                type: "success",
                                                message: response.data?.message,
                                              })
                                            );
                                          }
                                        });
                                    }
                                  }}
                                >
                                  <Icon color="action">lock_reset</Icon>
                                </EFActionBtn>
                                <EFActionBtn
                                  sx={{ backgroundColor: "white" }}
                                  onClick={async () => {
                                    const answer = await EFDialogConfirm({
                                      text: `ต้องการลบข้อมูล '${row.username}' หรือไม่?`,
                                      title: "ยืนยันการลบข้อมูล?",
                                      options: {
                                        trueButtonText: "ยืนยัน",
                                        falseButtonText: "ยกเลิก",
                                      },
                                    });
                                    if (answer) {
                                      httpClient
                                        .delete(`/user/${row.id}`)
                                        .then((response) => {
                                          if (response.status === 201) {
                                            fetchData();
                                            dispatch(
                                              snackbarAlertActions.setAlert({
                                                open: true,
                                                type: "success",
                                                message: response.data?.message,
                                              })
                                            );
                                          }
                                        });
                                    }
                                  }}
                                >
                                  <Icon color="action">delete</Icon>
                                </EFActionBtn>
                              </>
                            )}
                          </Stack>
                        </EFTableCell>
                      </EFTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default User;
