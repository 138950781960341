import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

const Button = styled(MuiButton)(({ theme }) => ({
  borderRadius: 12,
  color: theme.palette.common.white,
  minWidth: 120,
}));

const EFButton = (props) => {
  return <Button {...props} color={"secondary"} />;
};

export default EFButton;
