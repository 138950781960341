import {
  LOGIN_FETCHING,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
} from "./../constants";
import { httpClient } from "./../utils/httpClient";
import * as snackbarAlertActions from "./snackbarAlert.action";
import * as loadingActions from "./loading.action";

export const setStateToFetching = () => ({
  type: LOGIN_FETCHING,
});

export const setStateToSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const setStateToToFailed = (payload) => ({
  type: LOGIN_FAILED,
  payload,
});

export const setStateToLogout = (payload) => ({
  type: LOGOUT,
  payload,
});

export const login = ({ username, password }, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(setStateToFetching());
      dispatch(loadingActions.setLoading(true));
      const response = await httpClient.post(`/user/login`, {
        username,
        password,
      });
      dispatch(loadingActions.setLoading(false));
      if (response && response.data) {
        const ip = await httpClient.get(`/user/getIP`);
        const _data = response.data;
        const logined = {
          Logined: true,
          Username: _data.username,
          Access_token: _data.access_token,
          IP: ip.data !== "" ? ip.data.replace("::ffff:", "") : "Unknown",
        };
        localStorage.setItem("socialaraya.co.th", JSON.stringify(logined));
        dispatch(setStateToSuccess(true));

        dispatch(
          snackbarAlertActions.setAlert({
            open: true,
            type: "success",
            message: "Sign-in successfully",
          })
        );
        navigate("/banner");
      }
    } catch (error) {
      dispatch(loadingActions.setLoading(false));
      dispatch(setStateToToFailed(false));
      dispatch(
        snackbarAlertActions.setAlert({
          open: true,
          type: "error",
          message: error && (error?.response?.data?.error || error),
        })
      );
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem("socialaraya.co.th");
    dispatch(setStateToLogout(false));
  };
};

// export function logined() {
//     let Current = localStorage.getItem("socialaraya");
//     if (Current === null || Current === undefined) {
//         return false;
//     }
//     var bytes = CryptoJS.AES.decrypt(Current, 'Secret-Key-SLSH-Insurance');
//     var originalText = bytes.toString(CryptoJS.enc.Utf8);
//     if (originalText !== "") {
//         return JSON.parse(originalText).Logined;
//     }
//     return false;
// }

export function getCurrentUser() {
  let current = localStorage.getItem("socialaraya.co.th");
  if (current === null || current === undefined) {
    return null;
  }
  return JSON.parse(current);
}
