// import React, { useMemo, useState } from "react";
// import ReactQuill, { Quill } from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import ImageResize from "quill-image-resize-module-react";

// Quill.register("modules/imageResize", ImageResize);

// const EFTextEditor = (props) => {
//   const { dataForm, setDataForm } = props;
//   const quillRef = React.useRef(null);
//   const formats = [
//     "header",
//     "bold",
//     "italic",
//     "underline",
//     "strike",
//     "blockquote",
//     "list",
//     "bullet",
//     "indent",
//     "link",
//     "color",
//     "image",
//     "video",
//     "background",
//     "align",
//     "size",
//     // "font",
//   ];
//   const modules = useMemo(
//     () => ({
//       toolbar: {
//         container: [
//           [
//             // { font: [] },
//             { header: [1, 2, 3, 4, 5, 6, false] },
//           ],
//           [{ size: [] }],
//           ["bold", "italic", "underline", "blockquote"],
//           [{ align: ["right", "center", "justify"] }],
//           [
//             { list: "ordered" },
//             { list: "bullet" },
//             { indent: "-1" },
//             { indent: "+1" },
//           ],
//           ["link", "image", "video"],
//           [{ color: ["#0b4e75", "#b79c62", "black", "red"] }],
//           [{ background: ["#0b4e75", "#b79c62", "black", "red"] }],
//         ],
//       },
//       clipboard: {
//         matchVisual: false,
//       },
//       // imageResize: {
//       //   modules: ["Resize", "DisplaySize"],
//       // },
//     }),
//     []
//   );

//   const handleChange = (value) => {
//     // let has_attribues = delta.ops[1].attributes || "";
//     // console.log(has_attribues);
//     // const cursorPosition = e.quill.getSelection().index;
//     // this.quill.insertText(cursorPosition, "★");
//     // this.quill.setSelection(cursorPosition + 1);
//     setDataForm((prevState) => {
//       const newState = { ...prevState, content: value.toString() };
//       return newState;
//     });
//   };

//   return (
//     <>
//       <ReactQuill
//         theme="snow"
//         ref={quillRef}
//         modules={modules}
//         formats={formats}
//         value={dataForm.content?.toString()}
//         onChange={handleChange}
//         style={
//           {
//             // height: "48rem",
//             // flex: "2",
//             // display: "flex",
//             // flexDirection: "column",
//           }
//         }
//         preserveWhitespace={true}
//       />
//     </>
//   );
// };

// export default EFTextEditor;

import React, { useEffect, useRef } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  fontColor,
  fontSize,
  hiliteColor,
  list,
  table,
  formatBlock,
  textStyle,
  image,
  video,
  link,
} from "suneditor/src/plugins";

const EFTextEditor = (props) => {
  const { dataForm, setDataForm } = props;
  const editor = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const handleChange = (value) => {
    //Get Content Inside Editor
    setDataForm((prevState) => {
      const newState = { ...prevState, content: value };
      return newState;
    });
  };

  return (
    <SunEditor
      ref={editor}
      // lang="en"
      width="100%"
      height="100%"
      onChange={handleChange}
      setContents={dataForm.content.toString() || ""}
      setDefaultStyle="font-family: Noto Sans Thai; font-size: 16px;"
      setAllPlugins={false}
      setOptions={{
        height: "100%",
        minHeight: "50vh",
        buttonList: [
          // [
          //   // "font",
          //   // "formatBlock",
          //   // "fontSize",
          //   "align",
          //   "fontColor",
          //   "hiliteColor",
          //   "horizontalRule",
          //   "lineHeight",
          //   "list",
          //   // "paragraphStyle",
          //   "table",
          //   "link",
          //   "image",
          //   "video",
          // ],
          ["undo", "redo"],
          ["formatBlock", "fontSize"],
          [
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript",
            "removeFormat",
          ],
          [
            "fontColor",
            "hiliteColor",
            "outdent",
            "indent",
            "align",
            "horizontalRule",
            "list",
            "table",
          ],
          ["link", "image", "video", "preview"],
        ],
        position: "left",
        // imageGalleryLoadURL: "https://fake-images.glitch.me?limit=100",
        // imageGalleryLoadURL : [{id : 1,caption: "archives_02-06-2021-151954.jpg",url : "/content/archives/archives_02-06-2021-151954.jpg"}],
        plugins: [
          align,
          formatBlock,
          fontColor,
          fontSize,
          hiliteColor,
          list,
          table,
          textStyle,
          image,
          video,
          link,
        ],
      }}
      getSunEditorInstance={getSunEditorInstance}
    />
  );
};

export default EFTextEditor;

// import { useEffect } from "react";
// import { useQuill } from "react-quilljs";
// import BlotFormatter from "quill-blot-formatter";
// import "quill/dist/quill.snow.css";

// const EFTextEditor = (props) => {
//   const { dataForm, setDataForm } = props;
//   const { quill, quillRef, Quill } = useQuill({
//     modules: { blotFormatter: {} },
//   });

//   if (Quill && !quill) {
//     Quill.register("modules/blotFormatter", BlotFormatter);
//   }

//   useEffect(() => {
//     if (quill) {
//       quill.on("text-change", (delta, oldContents) => {
//         // console.log(quill.getText()); // Get text only
//         // console.log(quill.getContents()); // Get delta contents
//         // console.log(quill.root.innerHTML); // Get innerHTML using quill
//         // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
//         let content = quill.root.innerHTML;
//         setDataForm((prevState) => {
//           const newState = { ...prevState, content: content };
//           return newState;
//         });
//       });
//       console.log(dataForm.content);
//       quill.clipboard.dangerouslyPasteHTML(dataForm.content);
//     }
//   }, [quill, Quill]);

//   return (
//     <div>
//       <div ref={quillRef} />
//     </div>
//   );
// };

// export default EFTextEditor;
