import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#0b4e75",
    },
    secondary: {
      main: "#b79c62",
    },
    // success: {
    //   main: red.A400,
    // },
    // error: {
    //   main: red.A400,
    // },
    // default: {
    //   main: '#d3d4d5',
    // },
    background: {
      default: "#f0f0f0",
    },
    text: {
      primary: "#000",
      secondary: "#646464",
    },
  },
  typography: {
    fontFamily: [
      "Noto Sans Thai",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    h6: {
      fontWeight: "bolder",
    },
  },
});

export default theme;
