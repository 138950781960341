import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/authProvider ";

const PrivateRoute = () => {
  const auth = useAuth();
  if (auth && !auth.access?.Access_token) return <Navigate to="/signin" />;
  return <Outlet />;
};

export default PrivateRoute;
