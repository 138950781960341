import * as React from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  Switch,
  Typography,
} from "@mui/material";
import EFButton from "../../../components/EFButton";
import EFTextField from "../../../components/EFTextField";
import * as snackbarAlertActions from "../../../actions/snackbarAlert.action";
import * as loadingActions from "../../../actions/loading.action";
import { useDispatch } from "react-redux";
import { httpClient } from "../../../utils/httpClient";
import { useNavigate } from "react-router-dom";

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataForm, setDataForm] = React.useState({
    username: "",
    is_active: true,
  });

  const handleOnTextChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const validDataForm = () => {
    if (validEmpty(dataForm.username) === true) {
      return { status: false, message: "กรุณาระบุ 'รหัสผู้ใช้งาน'" };
    }
    return { status: true, message: "success" };
  };

  const validEmpty = (data) => {
    if (data === null || data === "" || typeof data === "undefined") {
      return true;
    }
    return false;
  };

  const handleOnSubmit = () => {
    if (validDataForm().status === true) {
      dispatch(loadingActions.setLoading(true));
      httpClient.post("/user/register", dataForm).then((response) => {
        dispatch(loadingActions.setLoading(false));
        if (response.status === 201 && response.data?.result.affectedRows > 0) {
          dispatch(
            snackbarAlertActions.setAlert({
              open: true,
              type: "success",
              message: response.data?.message,
            })
          );
          navigate("/settings");
        }
      });
    } else {
      dispatch(
        snackbarAlertActions.setAlert({
          open: true,
          type: "warning",
          message: validDataForm().message,
        })
      );
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" color={"primary"}>
            เพิ่มผู้ใช้งาน
          </Typography>
          <Typography variant="body1" color={"inherit"}>
            เพิ่มผู้ใช้งาน
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <EFButton
            variant="contained"
            startIcon={<Icon>save</Icon>}
            disableElevation
            onClick={handleOnSubmit}
          >
            บันทึก
          </EFButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container justifyContent={"center"} mt={4}>
          <Box sx={{ width: "100%", maxWidth: 500 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" color={"inherit"}>
                  รหัสผู้ใช้งาน
                </Typography>
                <EFTextField
                  fullWidth
                  placeholder="โปรดระบุ"
                  name="username"
                  value={dataForm.username}
                  onChange={handleOnTextChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color={"inherit"}>
                  การใช้งาน
                </Typography>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      disabled
                      color="secondary"
                      checked={dataForm.is_active || false}
                      onChange={(e) => {
                        setDataForm({
                          ...dataForm,
                          is_active: e.target.checked,
                        });
                      }}
                    />
                  }
                  // label="การแสดงผล : "
                  // labelPlacement="start"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Page;
