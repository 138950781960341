import React from "react";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";

const EFLoading = (props) => {
  const loading = useSelector((state) => state.loadingReducer);

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={loading.open}
      >
        <CircularProgress disableShrink color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default EFLoading;
