import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

const Button = styled(MuiButton)(({ theme }) => ({
  borderRadius: 8,
  height: 40,
  width: 40,
  minWidth: 40,
  maxWidth: 40,
  minHeight: 40,
  maxHeight: 40,
}));

const EFActionBtn = (props) => {
  return <Button {...props} />;
};

export default EFActionBtn;
