import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import EFButton from "../../components/EFButton";
import dayjs from "dayjs";
import EFTextField from "../../components/EFTextField";
import { DatePicker } from "@mui/x-date-pickers";
import * as snackbarAlertActions from "../../actions/snackbarAlert.action";
import * as loadingActions from "../../actions/loading.action";
import { useDispatch } from "react-redux";
import { httpClient } from "../../utils/httpClient";
import { useNavigate } from "react-router-dom";

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRefImage = React.useRef();
  const inputRefFile = React.useRef();
  const [imageFile, setImageFile] = React.useState(null);
  const [videoFile, setVideoFile] = React.useState(null);
  const [isEndDate, setIsEndDate] = React.useState(false);
  const [dataForm, setDataForm] = React.useState({
    subject: "",
    description: "",
    image_path: null,
    file_path: null,
    start: dayjs(),
    end: null,
    is_active: true,
  });

  const handleOnTextChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const onChooseImageFile = () => {
    inputRefImage.current.click();
  };

  const handleImageFileOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let fileSize = event.target.files[0].size / 1024 / 1024;
      fileSize = fileSize.toFixed(2);
      if (Number(fileSize) < 10) {
        if (event.target.files[0].type.match(imageTypeRegex)) {
          setImageFile(event.target.files[0]);
          const images = [],
            fileReaders = [];
          const fileReader = new FileReader();
          fileReaders.push(fileReader);
          fileReader.onload = (e) => {
            const { result } = e.target;
            if (result) {
              images.push(result);
            }
            setDataForm({
              ...dataForm,
              image_path: images[0],
            });
          };
          fileReader.readAsDataURL(event.target.files[0]);
        } else {
          dispatch(
            snackbarAlertActions.setAlert({
              open: true,
              type: "error",
              message: "กรุณาเลือกไฟล์นามสกุล png | jpg | jpeg เท่านั้น!",
            })
          );
        }
      } else {
        dispatch(
          snackbarAlertActions.setAlert({
            open: true,
            type: "error",
            message: "กรุณาเลือกไฟล์ที่มีขนาดไม่เกิน 5MB!",
          })
        );
      }
    }
  };

  const removeImageFile = () => {
    setImageFile(null);
    setDataForm({
      ...dataForm,
      image_path: null,
    });
  };

  //   const onChooseVideoFile = () => {
  //     inputRefFile.current.click();
  //   };
  //   const videoRef = React.useRef();
  //   React.useEffect(() => {
  //     videoRef.current?.load();
  //   }, [videoFile]);

  //   const handleVideoFileOnChange = (event) => {
  //     if (event.target.files && event.target.files.length > 0) {
  //       let fileSize = event.target.files[0].size / 1024 / 1024;
  //       fileSize = fileSize.toFixed(2);
  //       if (Number(fileSize) < 500) {
  //         if (event.target.files[0].type.includes("video")) {
  //           setVideoFile(URL.createObjectURL(event.target.files[0]));
  //           const videos = [],
  //             fileReaders = [];
  //           const fileReader = new FileReader();
  //           fileReaders.push(fileReader);
  //           fileReader.onload = (e) => {
  //             const { result } = e.target;
  //             if (result) {
  //               videos.push(result);
  //             }
  //             setDataForm({
  //               ...dataForm,
  //               video_path: videos[0],
  //             });
  //           };
  //           fileReader.readAsDataURL(event.target.files[0]);
  //         } else {
  //           dispatch(
  //             snackbarAlertActions.setAlert({
  //               open: true,
  //               type: "error",
  //               message: "กรุณาเลือกไฟล์วีดีโอเท่านั้น!",
  //             })
  //           );
  //         }
  //       } else {
  //         dispatch(
  //           snackbarAlertActions.setAlert({
  //             open: true,
  //             type: "error",
  //             message: "กรุณาเลือกไฟล์ที่มีขนาดไม่เกิน 500MB!",
  //           })
  //         );
  //       }
  //     }
  //   };

  //   const removeVideoFile = () => {
  //     setVideoFile(null);
  //     setDataForm({
  //       ...dataForm,
  //       video_path: null,
  //     });
  //   };

  const validDataForm = () => {
    if (validEmpty(dataForm.subject) === true) {
      return { status: false, message: "กรุณาระบุ 'หัวข้อ'" };
    } else if (validEmpty(dataForm.description) === true) {
      return { status: false, message: "กรุณาระบุ 'รายละเอียด'" };
    } else if (validEmpty(dataForm.start) === true) {
      return { status: false, message: "กรุณาระบุ 'การแสดงวันที่แสดง'" };
    } else if (isEndDate && validEmpty(dataForm.end) === true) {
      return { status: false, message: "กรุณาระบุ 'การแสดงวันที่สิ้นสุด'" };
    }
    return { status: true, message: "success" };
  };

  const validEmpty = (data) => {
    if (data === null || data === "" || typeof data === "undefined") {
      return true;
    }
    return false;
  };

  const handleOnSubmit = () => {
    if (validDataForm().status === true) {
      dispatch(loadingActions.setLoading(true));
      httpClient.post("/video", dataForm).then((response) => {
        dispatch(loadingActions.setLoading(false));
        if (response.status === 201 && response.data?.result.affectedRows > 0) {
          dispatch(
            snackbarAlertActions.setAlert({
              open: true,
              type: "success",
              message: response.data?.message,
            })
          );
          navigate("/video");
        }
      });
    } else {
      dispatch(
        snackbarAlertActions.setAlert({
          open: true,
          type: "warning",
          message: validDataForm().message,
        })
      );
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" color={"primary"}>
            สร้างบทความวิจัย
          </Typography>
          <Typography variant="body1" color={"inherit"}>
            สร้างบทความวิจัย
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <EFButton
            variant="contained"
            startIcon={<Icon>save</Icon>}
            disableElevation
            onClick={handleOnSubmit}
          >
            บันทึก
          </EFButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                color={"primary"}
                sx={{ fontWeight: "bolder" }}
              >
                รายละเอียดข้อมูลบทความ
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent={"flex-end"}>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="secondary"
                    checked={dataForm.is_active || false}
                    onChange={(e) => {
                      setDataForm({ ...dataForm, is_active: e.target.checked });
                    }}
                  />
                }
                label="การแสดงผล : "
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color={"inherit"}>
                หัวข้อ *
              </Typography>
              <EFTextField
                fullWidth
                placeholder="โปรดระบุ"
                name="subject"
                value={dataForm.subject}
                onChange={handleOnTextChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color={"inherit"}>
                รายละเอียด *
              </Typography>
              <EFTextField
                fullWidth
                placeholder="โปรดระบุ"
                name="description"
                multiline
                rows={5}
                value={dataForm.description}
                onChange={handleOnTextChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                color={"inherit"}
                sx={{ height: "28px" }}
              >
                วันที่เริ่มแสดง *
              </Typography>
              <DatePicker
                // disablePast
                value={dayjs(dataForm.start) || null}
                onChange={(newValue) => {
                  setDataForm({
                    ...dataForm,
                    start: newValue,
                  });
                }}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "12px !important",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="space-between"
              >
                <Typography variant="body1" color={"inherit"}>
                  วันที่สิ้นสุด *
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      sx={{ padding: 0 }}
                      color="secondary"
                      value={!isEndDate}
                      onChange={(event) => {
                        setIsEndDate(!isEndDate);
                        if (isEndDate) {
                          setDataForm({
                            ...dataForm,
                            end: null,
                          });
                        }
                      }}
                    />
                  }
                  label="ไม่กำหนด"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                />
              </Stack>
              <DatePicker
                disabled={!isEndDate}
                minDate={dayjs(dataForm.start)}
                value={dataForm.end === null ? null : dayjs(dataForm.end)}
                onChange={(newValue) => {
                  setDataForm({
                    ...dataForm,
                    end: newValue,
                  });
                }}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "12px !important",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              test
            </Grid>
            <Grid item xs={12}>
              {!imageFile && (
                <Stack spacing={1} alignItems={"center"} sx={{ width: "100%" }}>
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    ref={inputRefImage}
                    onChange={handleImageFileOnChange}
                    style={{ display: "none" }}
                  />
                  <Button
                    sx={{
                      width: "100%",
                      maxWidth: 240,
                      maxHeight: 300,
                      minWidth: 240,
                      minHeight: 300,
                      fontSize: "18px",
                      fontWeight: "500",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "15px",
                      color: "#0b4e75",
                      backgroundColor: "#fff",
                      border: "1.5px dashed #b79c62",
                      borderRadius: "10px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }}
                    onClick={onChooseImageFile}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Icon
                        sx={{
                          fontSize: "50px",
                        }}
                        color="secondary"
                      >
                        cloud_upload
                      </Icon>
                    </Box>
                    เลือกรูปภาพ
                  </Button>
                  <Typography variant="body2" color={"text.secondary"}>
                    ไฟล์ : .png/jpg Resolution : 4:5
                  </Typography>
                  <Typography variant="caption" color={"text.secondary"}>
                    ขนาดที่แนะนำ : 600x480 ขนาดไฟล์สูงสุดไม่เกิน : 10 MB
                  </Typography>
                </Stack>
              )}
              {imageFile && (
                <Stack spacing={1} alignItems={"center"} sx={{ width: "100%" }}>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      maxWidth: 240,
                      maxHeight: 300,
                      minWidth: 240,
                      minHeight: 300,
                      fontSize: "18px",
                      fontWeight: "500",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "15px",
                      color: "#0b4e75",
                      backgroundColor: "#fff",
                      border: "1.5px dashed #b79c62",
                      borderRadius: "10px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }}
                    src={URL.createObjectURL(imageFile)}
                    alt="Thumb"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{imageFile.name}</Typography>
                    <Typography
                      onClick={removeImageFile}
                      variant="body2"
                      color="secondary"
                      sx={{
                        marginLeft: 2,
                        ": hover": {
                          cursor: "pointer",
                          textDecoration: "underline",
                        },
                      }}
                      // startIcon={<Icon>delete</Icon>}
                    >
                      ลบ
                    </Typography>
                  </Box>
                </Stack>
              )}
            </Grid>
          </Grid>

          {/* {!videoFile && (
            <Stack spacing={1} alignItems={"center"} sx={{ width: "100%" }}>
              <input
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                ref={inputRefFile}
                onChange={handleVideoFileOnChange}
                style={{ display: "none" }}
              />
              <Button
                sx={{
                  width: "100%",
                  height: 500,
                  maxWidth: 900,
                  fontSize: "18px",
                  fontWeight: "500",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                  color: "#0b4e75",
                  backgroundColor: "#fff",
                  border: "1.5px dashed #b79c62",
                  borderRadius: "20px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                }}
                onClick={onChooseVideoFile}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Icon
                    sx={{
                      fontSize: "50px",
                    }}
                    color="secondary"
                  >
                    play_circle_filled
                  </Icon>
                </Box>
                เลือกวีดีโอ
              </Button>       
              <Typography variant="caption" color={"text.secondary"}>
                ขนาดที่แนะนำ : ขนาดไฟล์สูงสุดไม่เกิน : 10 MB
              </Typography>
            </Stack>
          )}
          {videoFile && (
            <Stack spacing={1} alignItems={"center"} sx={{ width: "100%" }}>
              <video
                style={{
                  width: "100%",
                  height: "100%",
                  maxWidth: 900,
                  maxHeight: 500,
                  fontSize: "18px",
                  fontWeight: "500",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                  color: "#0b4e75",
                  backgroundColor: "#fff",
                  border: "1.5px dashed #b79c62",
                  borderRadius: "20px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                }}
                ref={videoRef}
                controls
              >
                <source src={videoFile} />
              </video>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">{videoFile.name}</Typography>
                <Typography
                  onClick={removeVideoFile}
                  variant="body2"
                  color="secondary"
                  sx={{
                    marginLeft: 2,
                    ": hover": {
                      cursor: "pointer",
                      textDecoration: "underline",
                    },
                  }}
                  // startIcon={<Icon>delete</Icon>}
                >
                  ลบ
                </Typography>
              </Box>
            </Stack>
          )} */}
        </Grid>
      </Grid>
    </Box>
  );
};
export default Page;
