import * as React from "react";
// export const apiUrl = "http://localhost:5001/api/v1";
// export const imageUrl = "http://localhost:5001";

export const apiUrl = "https://api.socialaraya.co.th/api/v1";
export const imageUrl = "https://api.socialaraya.co.th";

export const LOGIN_FETCHING = "LOGIN_FETCHING";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const LOADING = "LOADING";

export const ALERT = "ALERT";
export const ALERT_CLOSE = "ALERT_CLOSE";

export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";
export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";

export const toDataURL = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

// export const toDataURL = (url) =>
//   fetch(url, {
//     method: "GET",
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//     },
//   })
//     .then((response) => response.blob())
//     .then(
//       (blob) =>
//         new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = () => resolve(reader.result);
//           reader.onerror = reject;
//           reader.readAsDataURL(blob);
//         })
//     );

export function dataURLtoFile(url, filename) {
  var arr = url.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
