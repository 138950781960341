import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& fieldset": {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
}));
