import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Grid,
  Icon,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../hooks/authProvider ";
import { httpClient } from "../utils/httpClient";
import { useNavigate } from "react-router-dom";

export default function SignInSide() {
  const auth = useAuth();
  const navigate = useNavigate();
  const myRef = React.useRef();
  const [dataLogin, setDataLogin] = React.useState({
    username: "",
    password: "",
  });

  React.useEffect(() => {
    if (auth.auth?.Access_token) {
      verifyToken();
    }
  }, []);

  async function verifyToken() {
    const response = await httpClient.get(`/user/verifyToken`);
    console.log("work");
    if (response.status === 200 && response && response.data) {
      navigate("/banner");
    } else {
      auth.signOut();
    }
  }

  const handleOnTextChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDataLogin({
      ...dataLogin,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (myRef.current.reportValidity()) {
      auth.signIn(dataLogin);
      return;
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {/* <form ref={myRef} onSubmit={handleSubmit} autoComplete="off"> */}
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h3" mb={8}>
            Social Araya
          </Typography>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <Icon>lock</Icon>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            ref={myRef}
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
            autoComplete="off"
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="Username"
              name="username"
              value={dataLogin.username}
              onChange={handleOnTextChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              value={dataLogin.password}
              onChange={handleOnTextChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, minHeight: 50 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        {/* </form> */}
      </Grid>
    </Grid>
  );
}
