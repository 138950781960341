import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";

const Page401 = () => {
  return (
    <Box>
      <Stack container direction={"column"} spacing={2} alignItems={"center"}>
        <Typography
          variant="h1"
          sx={{ fontSize: "20rem !important", color: "#a7bbc7" }}
        >
          401
        </Typography>
        <Typography
          variant="h1"
          sx={{ fontSize: "8rem !important" }}
          color={"primary"}
        >
          unauthorized
        </Typography>
      </Stack>
    </Box>
  );
};
export default Page401;
