import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { thunk } from "redux-thunk";
import reducers from "./reducers";
import { responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import Theme from "./theme/themeRegistry";
import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/th";
import EFSnackbar from "./components/EFSnackbar";
import EFLoading from "./components/EFLoading";

var middlewares = (middlewares = applyMiddleware(thunk, logger));
const store = createStore(reducers, middlewares);

let theme = responsiveFontSizes(Theme);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
          <CssBaseline />
          <App />
          <EFLoading />
          <EFSnackbar />
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// window.onload = function () {
//   ReactDOM.render(
//     <React.StrictMode></React.StrictMode>,
//     document.getElementById("root")
//   );
// };

reportWebVitals();
