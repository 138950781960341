import * as React from "react";
import {
  Box,
  Divider,
  Grid,
  Icon,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EFButton from "../../components/EFButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EFTableCell, EFTableRow } from "../../components/EFTable";
import { httpClient } from "../../utils/httpClient";
import _ from "lodash";
import EFActionBtn from "../../components/EFActionBtn";
import { EFDialogConfirm } from "../../components/EFDialogConfirm";
import * as snackbarAlertActions from "../../actions/snackbarAlert.action";
import * as loadingActions from "../../actions/loading.action";

const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataFetch, setDataFetch] = React.useState([]);
  const [dataClone, setDataClone] = React.useState([]);

  React.useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    dispatch(loadingActions.setLoading(true));
    const response = await httpClient.get(`/faq`);
    dispatch(loadingActions.setLoading(false));
    if (response && response.status === 200 && response.data) {
      setDataFetch(response.data);
      setDataClone(_.clone(response.data));
    }
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" color={"primary"}>
            การจัดการคำถามที่พบบ่อย
          </Typography>
          <Typography variant="body1" color={"inherit"}>
            จัดการคำถามที่พบบ่อย
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <Stack direction={"row"} spacing={1}>
            <EFButton
              variant="contained"
              startIcon={<Icon>quiz</Icon>}
              disableElevation
              onClick={() => {
                navigate("/faq/create");
              }}
            >
              สร้างคำถาม
            </EFButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ marginBottom: 2 }}>
                  <EFTableCell sx={{ width: 120 }} align="center">
                    ลำดับ
                  </EFTableCell>
                  <EFTableCell align="center">คำถาม</EFTableCell>
                  <EFTableCell align="center">คำตอบ</EFTableCell>
                  <EFTableCell align="center" sx={{ width: 120 }}>
                    การแสดงผล
                  </EFTableCell>
                  <EFTableCell sx={{ width: 150 }}></EFTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataFetch &&
                  dataFetch.map((row, index) => {
                    return (
                      <EFTableRow key={index}>
                        <EFTableCell align="center">{index + 1}</EFTableCell>
                        <EFTableCell>
                          <Typography>{row.question}</Typography>
                        </EFTableCell>
                        <EFTableCell>
                          <Typography>{row.answer}</Typography>
                        </EFTableCell>
                        <EFTableCell align="center">
                          {row.is_active ? (
                            <Typography variant="body2" color={"secondary"}>
                              แสดง
                            </Typography>
                          ) : (
                            <Typography variant="body2" color={"error"}>
                              ไม่แสดง
                            </Typography>
                          )}
                        </EFTableCell>
                        <EFTableCell>
                          <Stack direction={"row"} spacing={1}>
                            <EFActionBtn
                              sx={{ backgroundColor: "white" }}
                              onClick={() => {
                                navigate(`/faq/edit/${row.id}`);
                              }}
                            >
                              <Icon color="action">edit</Icon>
                            </EFActionBtn>
                            <EFActionBtn
                              sx={{ backgroundColor: "white" }}
                              onClick={async () => {
                                const answer = await EFDialogConfirm({
                                  text: `ต้องการลบข้อมูล '${row.question}' หรือไม่?`,
                                  title: "ยืนยันการลบข้อมูล?",
                                  options: {
                                    trueButtonText: "ยืนยัน",
                                    falseButtonText: "ยกเลิก",
                                  },
                                });
                                if (answer) {
                                  httpClient
                                    .delete(`/faq/${row.id}`)
                                    .then((response) => {
                                      if (response.status === 201) {
                                        fetchData();
                                        dispatch(
                                          snackbarAlertActions.setAlert({
                                            open: true,
                                            type: "success",
                                            message: response.data?.message,
                                          })
                                        );
                                      }
                                    });
                                }
                              }}
                            >
                              <Icon color="action">delete</Icon>
                            </EFActionBtn>
                          </Stack>
                        </EFTableCell>
                      </EFTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Page;
