import * as React from "react";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  Switch,
  Typography,
} from "@mui/material";
import EFButton from "../../components/EFButton";
import EFTextField from "../../components/EFTextField";
import * as snackbarAlertActions from "../../actions/snackbarAlert.action";
import * as loadingActions from "../../actions/loading.action";
import { useDispatch } from "react-redux";
import { httpClient } from "../../utils/httpClient";
import { useNavigate, useParams } from "react-router-dom";

const Page = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataForm, setDataForm] = React.useState({
    id: null,
    question: "",
    answer: "",
    is_active: true,
  });

  React.useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);

  async function fetchData() {
    dispatch(loadingActions.setLoading(true));
    const response = await httpClient.get(`/faq/${id}`);
    dispatch(loadingActions.setLoading(false));
    if (response.status === 200 && response.data.length > 0) {
      const _data = response.data[0];
      setDataForm({
        ...dataForm,
        id: Number(id),
        question: _data.question,
        answer: _data.answer,
        is_active: Boolean(_data.is_active),
      });
    }
  }

  const handleOnTextChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const validDataForm = () => {
    if (validEmpty(dataForm.question) === true) {
      return { status: false, message: "กรุณาระบุ 'คำถาม'" };
    } else if (validEmpty(dataForm.answer) === true) {
      return { status: false, message: "กรุณาระบุ 'คำตอบ'" };
    }
    return { status: true, message: "success" };
  };

  const validEmpty = (data) => {
    if (data === null || data === "" || typeof data === "undefined") {
      return true;
    }
    return false;
  };

  const handleOnSubmit = () => {
    if (validDataForm().status === true) {
      httpClient.put("/faq", dataForm).then((response) => {
        if (response.status === 201 && response.data?.result.affectedRows > 0) {
          dispatch(
            snackbarAlertActions.setAlert({
              open: true,
              type: "success",
              message: response.data?.message,
            })
          );
          navigate("/faq");
        }
      });
    } else {
      dispatch(
        snackbarAlertActions.setAlert({
          open: true,
          type: "warning",
          message: validDataForm().message,
        })
      );
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" color={"primary"}>
            แก้ไขคำถาม
          </Typography>
          <Typography variant="body1" color={"inherit"}>
            แก้ไขคำถาม
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <EFButton
            variant="contained"
            startIcon={<Icon>save</Icon>}
            disableElevation
            onClick={handleOnSubmit}
          >
            บันทึก
          </EFButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} mt={4} container justifyContent={"center"}>
          <Box sx={{ width: "100%", maxWidth: 800 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" color={"inherit"}>
                  คำถาม *
                </Typography>
                <EFTextField
                  fullWidth
                  placeholder="โปรดระบุ"
                  multiline
                  rows={3}
                  name="question"
                  value={dataForm.question}
                  onChange={handleOnTextChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color={"inherit"}>
                  คำตอบ *
                </Typography>
                <EFTextField
                  fullWidth
                  placeholder="โปรดระบุ"
                  multiline
                  rows={3}
                  name="answer"
                  value={dataForm.answer}
                  onChange={handleOnTextChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color={"inherit"}>
                  การใช้งาน
                </Typography>
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      color="secondary"
                      checked={dataForm.is_active || false}
                      onChange={(e) => {
                        setDataForm({
                          ...dataForm,
                          is_active: e.target.checked,
                        });
                      }}
                    />
                  }
                  // label="การแสดงผล : "
                  // labelPlacement="start"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Page;
