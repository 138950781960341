import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
// import * as headerActions from "./../../actions/header.action";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const MenuList = [
  {
    path: "/banner",
    pagename: "แบนเนอร์",
    icon: "web",
    orderby: 0,
  },
  // {
  //   path: "/video",
  //   pagename: "วีดีโอ",
  //   icon: "play_circle_filled",
  //   orderby: 1,
  // },
  {
    path: "/content",
    pagename: "สื่อวิจัยและข่าวสาร",
    icon: "article",
    orderby: 2,
  },
  {
    path: "/research",
    pagename: "บทความวิจัย",
    icon: "article",
    orderby: 3,
  },
  {
    path: "/faq",
    pagename: "คำถามที่พบบ่อย",
    icon: "quiz",
    orderby: 5,
  },
  {
    path: "/settings",
    pagename: "ตั้งค่า",
    icon: "settings",
    orderby: 12,
  },
];

const Menu = (props) => {
  const theme = useTheme();

  return (
    <Drawer variant="permanent" open={props.open}>
      <DrawerHeader>
        <IconButton onClick={props.handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <Icon>chevron_right</Icon>
          ) : (
            <Icon>chevron_left</Icon>
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {MenuList.map((item, index) => {
          return (
            <ListItem
              key={item.pagename}
              disablePadding
              sx={{ display: "block" }}
              component={Link}
              to={item.path}
              onClick={() => {}}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Icon>{item.icon !== "" ? item.icon : "description"}</Icon>
                </ListItemIcon>
                <StyledListItemText
                  primary={item.pagename}
                  sx={{ opacity: props.open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Menu;
