import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as snackbarAlertActions from "../actions/snackbarAlert.action";

const EFSnackbar = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackbarAlertReducer);
  const handleClose = () => {
    dispatch(snackbarAlertActions.closeAlert());
  };

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={snackbar.time}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={snackbar.type}
        variant="filled"
        sx={{ minWidth: 300, width: "100%" }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default EFSnackbar;
