import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import EFButton from "../../components/EFButton";
import dayjs from "dayjs";
import EFTextField from "../../components/EFTextField";
import { DatePicker } from "@mui/x-date-pickers";
import * as snackbarAlertActions from "../../actions/snackbarAlert.action";
import * as loadingActions from "../../actions/loading.action";
import { useDispatch } from "react-redux";
import { httpClient } from "../../utils/httpClient";
import { useNavigate } from "react-router-dom";

const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;
const Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRefFile = React.useRef();
  const [bannerFile, setBannerFile] = React.useState(null);
  const [isEndDate, setIsEndDate] = React.useState(false);
  const [dataForm, setDataForm] = React.useState({
    image_path: null,
    url: "",
    start: dayjs(),
    end: null,
    is_active: true,
  });

  const handleOnTextChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const onChooseBannerFile = () => {
    inputRefFile.current.click();
  };

  const handleBannerFileOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let fileSize = event.target.files[0].size / 1024 / 1024;
      fileSize = fileSize.toFixed(2);
      if (Number(fileSize) < 10) {
        if (event.target.files[0].type.match(imageTypeRegex)) {
          setBannerFile(event.target.files[0]);
          const images = [],
            fileReaders = [];
          const fileReader = new FileReader();
          fileReaders.push(fileReader);
          fileReader.onload = (e) => {
            const { result } = e.target;
            if (result) {
              images.push(result);
            }
            setDataForm({
              ...dataForm,
              image_path: images[0],
            });
          };
          fileReader.readAsDataURL(event.target.files[0]);
        } else {
          dispatch(
            snackbarAlertActions.setAlert({
              open: true,
              type: "error",
              message: "กรุณาเลือกไฟล์นามสกุล png | jpg | jpeg เท่านั้น!",
            })
          );
        }
      } else {
        dispatch(
          snackbarAlertActions.setAlert({
            open: true,
            type: "error",
            message: "กรุณาเลือกไฟล์ที่มีขนาดไม่เกิน 5MB!",
          })
        );
      }
    }
  };

  const removeBannerFile = () => {
    setBannerFile(null);
    setDataForm({
      ...dataForm,
      image_path: null,
    });
  };

  const validDataForm = () => {
    if (validEmpty(dataForm.start) === true) {
      return { status: false, message: "กรุณาระบุ 'การแสดงวันที่แสดง'" };
    }
    return { status: true, message: "success" };
  };

  const validEmpty = (data) => {
    if (
      data === null ||
      data === "" ||
      typeof data === "undefined" ||
      data === "<p><br></p>"
    ) {
      return true;
    }
    return false;
  };

  const handleOnSubmit = () => {
    if (validDataForm().status === true) {
      dispatch(loadingActions.setLoading(true));
      httpClient.post("/banner", dataForm).then((response) => {
        dispatch(loadingActions.setLoading(false));
        if (response.status === 201 && response.data?.result.affectedRows > 0) {
          dispatch(
            snackbarAlertActions.setAlert({
              open: true,
              type: "success",
              message: response.data?.message,
            })
          );
          navigate("/banner");
        }
      });
    } else {
      dispatch(
        snackbarAlertActions.setAlert({
          open: true,
          type: "warning",
          message: validDataForm().message,
        })
      );
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" color={"primary"}>
            สร้างแบนเนอร์
          </Typography>
          <Typography variant="body1" color={"inherit"}>
            สร้างแบนเนอร์
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <EFButton
            variant="contained"
            startIcon={<Icon>save</Icon>}
            disableElevation
            onClick={handleOnSubmit}
          >
            บันทึก
          </EFButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                color={"primary"}
                sx={{ fontWeight: "bolder" }}
              >
                รายละเอียดข้อมูลแบนเนอร์
              </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent={"flex-end"}>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="secondary"
                    checked={dataForm.is_active || false}
                    onChange={(e) => {
                      setDataForm({ ...dataForm, is_active: e.target.checked });
                    }}
                  />
                }
                label="การแสดงผล : "
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color={"inherit"}>
                การลิงค์
              </Typography>
              <EFTextField
                fullWidth
                placeholder="ใช้สำหรับลิงค์ไปยังหน้าเนื้อหา"
                name="url"
                value={dataForm.url}
                onChange={handleOnTextChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                color={"inherit"}
                sx={{ height: "28px" }}
              >
                วันที่เริ่มแสดง *
              </Typography>
              <DatePicker
                // disablePast
                value={dayjs(dataForm.start) || null}
                onChange={(newValue) => {
                  setDataForm({
                    ...dataForm,
                    start: newValue,
                  });
                }}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "12px !important",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="space-between"
              >
                <Typography variant="body1" color={"inherit"}>
                  วันที่สิ้นสุด *
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      sx={{ padding: 0 }}
                      color="secondary"
                      value={!isEndDate}
                      onChange={(event) => {
                        setIsEndDate(!isEndDate);
                        if (isEndDate) {
                          setDataForm({
                            ...dataForm,
                            end: null,
                          });
                        }
                      }}
                    />
                  }
                  label="ไม่กำหนด"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                />
              </Stack>
              <DatePicker
                disabled={!isEndDate}
                minDate={dayjs(dataForm.start)}
                value={dataForm.end === null ? null : dayjs(dataForm.end)}
                onChange={(newValue) => {
                  setDataForm({
                    ...dataForm,
                    end: newValue,
                  });
                }}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-formControl": {
                    borderRadius: "12px !important",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} container justifyContent={"center"}>
          {!bannerFile && (
            <Stack spacing={1} alignItems={"center"} sx={{ width: "100%" }}>
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                ref={inputRefFile}
                onChange={handleBannerFileOnChange}
                style={{ display: "none" }}
              />
              <Button
                sx={{
                  width: "100%",
                  height: 500,
                  maxWidth: 900,
                  fontSize: "18px",
                  fontWeight: "500",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                  color: "#0b4e75",
                  backgroundColor: "#fff",
                  border: "1.5px dashed #b79c62",
                  borderRadius: "20px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                }}
                onClick={onChooseBannerFile}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Icon
                    sx={{
                      fontSize: "50px",
                    }}
                    color="secondary"
                  >
                    cloud_upload
                  </Icon>
                </Box>
                เลือกรูปภาพ
              </Button>
              <Typography variant="body2" color={"text.secondary"}>
                ไฟล์ : .png/jpg Resolution : 16:9
              </Typography>
              <Typography variant="caption" color={"text.secondary"}>
                ขนาดที่แนะนำ : 1920x1080 ขนาดไฟล์สูงสุดไม่เกิน : 10 MB
              </Typography>
            </Stack>
          )}
          {bannerFile && (
            <Stack spacing={1} alignItems={"center"} sx={{ width: "100%" }}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  maxWidth: 900,
                  maxHeight: 500,
                  fontSize: "18px",
                  fontWeight: "500",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                  color: "#0b4e75",
                  backgroundColor: "#fff",
                  border: "1.5px dashed #b79c62",
                  borderRadius: "20px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                }}
                src={URL.createObjectURL(bannerFile)}
                alt="Thumb"
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">{bannerFile.name}</Typography>
                <Typography
                  onClick={removeBannerFile}
                  variant="body2"
                  color="secondary"
                  sx={{
                    marginLeft: 2,
                    ": hover": {
                      cursor: "pointer",
                      textDecoration: "underline",
                    },
                  }}
                  // startIcon={<Icon>delete</Icon>}
                >
                  ลบ
                </Typography>
              </Box>
            </Stack>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default Page;
