import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TabContext from "@mui/lab/TabContext";
import MuiTabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import MuiTabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import User from "./user/page";
import { useAuth } from "../../hooks/authProvider ";
import { useNavigate } from "react-router-dom";

const TabList = styled(MuiTabList)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "unset",
  },
  "& .MuiButtonBase-root.MuiTab-root": {
    color: theme.palette.primary.main,
    // transition: "color 0.2s ease-in-out",
    border: `1px solid black`,
    "&.Mui-selected": {
      color: "white",
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const TabPanel = styled(MuiTabPanel)(({ theme }) => ({
  padding: `${theme.spacing(2)} 0`,
}));

const Page = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [tab, setTab] = React.useState("1");
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  React.useEffect(() => {
    if (auth && auth.access?.UserType !== 1) {
      navigate("/401");
    }
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" color={"primary"}>
            การตั้งค่า
          </Typography>
          <Typography variant="body1" color={"inherit"}>
            การตั้งค่าระบบ
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange}>
                <Tab label="ผู้ใช้งาน" value="1" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <User />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Page;
