import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar as MuiAppBar,
  CssBaseline,
  Icon,
  IconButton,
  Toolbar,
  Typography,
  MenuItem,
  Menu as MuiMenu,
  Button,
} from "@mui/material";
import Menu from "./menu";
import { useAuth } from "../../hooks/authProvider ";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = (props) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <MuiMenu
      id={menuId}
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          navigate(`/settings/user/reset/${auth.access?.Username}`);
        }}
      >
        Change Password
      </MenuItem>
      <MenuItem
        onClick={() => {
          auth.signOut();
        }}
      >
        Sign Out
      </MenuItem>
    </MuiMenu>
  );

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={props.open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(props.open && { display: "none" }),
            }}
          >
            <Icon>menu</Icon>
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Admin Panel
          </Typography>
          {auth && (
            <div>
              {/* <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
               
              </IconButton> */}
              <Button
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                sx={{ color: "white" }}
                startIcon={<Icon>account_circle</Icon>}
              >
                {auth?.access?.Username || ""}
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Menu open={props.open} handleDrawerClose={props.handleDrawerClose} />
    </>
  );
};

export default Header;
