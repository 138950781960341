import * as React from "react";
import { Box, Divider, Grid, Icon, Typography } from "@mui/material";
import EFButton from "../../../components/EFButton";
import EFTextField from "../../../components/EFTextField";
import * as snackbarAlertActions from "../../../actions/snackbarAlert.action";
import * as loadingActions from "../../../actions/loading.action";
import { useDispatch } from "react-redux";
import { httpClient } from "../../../utils/httpClient";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/authProvider ";

const Page = () => {
  const { username } = useParams();
  const auth = useAuth();
  const dispatch = useDispatch();
  const [dataForm, setDataForm] = React.useState({
    username: "",
    newPassword: "",
    reNewPassword: "",
    oldPassword: "",
  });

  React.useEffect(() => {
    if (username) {
      fetchData();
    }
  }, []);

  async function fetchData() {
    dispatch(loadingActions.setLoading(true));
    const response = await httpClient.get(
      `/user/getUserByUserName/${username}`
    );
    dispatch(loadingActions.setLoading(false));
    if (response.status === 200 && response.data.length > 0) {
      const _data = response.data[0];
      setDataForm({
        ...dataForm,
        username: _data.username,
      });
    }
  }

  const handleOnTextChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDataForm({
      ...dataForm,
      [name]: value,
    });
  };

  const validDataForm = () => {
    if (validEmpty(dataForm.newPassword) === true) {
      return { status: false, message: "กรุณาระบุ 'รหัสผ่านใหม่'" };
    } else if (validEmpty(dataForm.reNewPassword) === true) {
      return { status: false, message: "กรุณาระบุ 'ยืนยันรหัสผ่านใหม่'" };
    } else if (validEmpty(dataForm.oldPassword) === true) {
      return { status: false, message: "กรุณาระบุ 'ยืนยันรหัสผ่านใหม่'" };
    } else if (dataForm.newPassword !== dataForm.reNewPassword) {
      return { status: false, message: "กรุณาระบุผ่านใหม่ให้ตรงกัน" };
    }
    return { status: true, message: "success" };
  };

  const validEmpty = (data) => {
    if (data === null || data === "" || typeof data === "undefined") {
      return true;
    }
    return false;
  };

  const handleOnSubmit = async () => {
    if (validDataForm().status === true) {
      try {
        dispatch(loadingActions.setLoading(true));
        const response = await httpClient.post(
          "/user/changePassword",
          dataForm
        );
        dispatch(loadingActions.setLoading(false));
        if (
          response &&
          response.status === 201 &&
          response.data?.result.affectedRows > 0
        ) {
          dispatch(
            snackbarAlertActions.setAlert({
              open: true,
              type: "success",
              message: response.data?.message,
            })
          );
          auth.signOut();
        }
      } catch (error) {
        dispatch(loadingActions.setLoading(false));
        dispatch(
          snackbarAlertActions.setAlert({
            open: true,
            type: "error",
            message: error && (error?.response?.data?.error || error),
          })
        );
      }
    } else {
      dispatch(
        snackbarAlertActions.setAlert({
          open: true,
          type: "warning",
          message: validDataForm().message,
        })
      );
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" color={"primary"}>
            เปลี่ยนรหัสผ่าน
          </Typography>
          <Typography variant="body1" color={"inherit"}>
            เปลี่ยนรหัสผ่าน
          </Typography>
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <EFButton
            variant="contained"
            startIcon={<Icon>save</Icon>}
            disableElevation
            onClick={handleOnSubmit}
          >
            บันทึก
          </EFButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container justifyContent={"center"} mt={4} mb={10}>
          <Box
            component={"form"}
            autoComplete="off"
            sx={{ width: "100%", maxWidth: 500 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" color={"inherit"}>
                  รหัสผู้ใช้งาน
                </Typography>
                <EFTextField
                  fullWidth
                  placeholder="โปรดระบุ"
                  name="username"
                  value={dataForm.username}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color={"inherit"}>
                  รหัสผ่านปัจจุบัน
                </Typography>
                <EFTextField
                  fullWidth
                  placeholder="โปรดระบุ"
                  name="oldPassword"
                  type="password"
                  value={dataForm.oldPassword}
                  onChange={handleOnTextChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color={"inherit"}>
                  รหัสผ่านใหม่
                </Typography>
                <EFTextField
                  fullWidth
                  placeholder="โปรดระบุ"
                  name="newPassword"
                  type="password"
                  value={dataForm.newPassword}
                  onChange={handleOnTextChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color={"inherit"}>
                  ยืนยันรหัสผ่านใหม่
                </Typography>
                <EFTextField
                  fullWidth
                  placeholder="โปรดระบุ"
                  name="reNewPassword"
                  type="password"
                  value={dataForm.reNewPassword}
                  onChange={handleOnTextChange}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Page;
