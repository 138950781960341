import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const Button = styled(MuiButton)(({ theme }) => ({
  borderRadius: 12,
  color: theme.palette.common.white,
  height: 48,
  minWidth: 100,
  backgroundColor: "#b79c62",
  "&:hover": {
    backgroundColor: "#806d44",
  },
}));

const confirmRoot = document.createElement("div");
const body = document.querySelector("body");
body?.appendChild(confirmRoot);

function ConfirmDialog({ title, text, giveAnswer, options }) {
  return (
    <Dialog fullWidth maxWidth="sm" open disableBackdropClick>
      <DialogTitle
        sx={{
          backgroundColor: "#b79c62",
          color: "white",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ padding: "24px !important" }}>
        <DialogContentText sx={{ color: "black" }}>{text}</DialogContentText>
        <br />
      </DialogContent>
      <DialogActions sx={{ padding: "16px 24px !important" }}>
        <Button
          variant="outlined"
          disableElevation
          onClick={() => giveAnswer(false)}
          sx={{
            backgroundColor: "white",
            color: "#b79c62",
            border: "1px solid #b79c62 !important",
            "&:hover": {
              backgroundColor: "#b79c620a",
              border: "unset",
            },
          }}
        >
          {options?.falseButtonText ? options?.falseButtonText : "Disagree"}
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={() => giveAnswer(true)}
          autoFocus
        >
          {options?.trueButtonText ? options?.trueButtonText : "Agree"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const EFDialogConfirm = ({ text, title, options }) =>
  new Promise((res) => {
    const giveAnswer = (answer) => {
      ReactDOM.unmountComponentAtNode(confirmRoot);
      res(answer);
    };
    ReactDOM.render(
      <ConfirmDialog
        title={title}
        text={text}
        giveAnswer={giveAnswer}
        options={options}
      />,
      confirmRoot
    );
  });
