import { LOADING } from "../constants";

const initialState = {
  open: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING:
      return { ...state, open: payload };
    default:
      return state;
  }
};
